import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Price } from '../models/price.model';
import { AlertController } from '@ionic/angular';
import { AppGlobals } from '../app.globals';

@Component({
  selector: 'app-lookup-price-page',
  templateUrl: './lookup-price-page.page.html',
  styleUrls: ['./lookup-price-page.page.scss'],
})
export class LookupPricePagePage implements OnInit {
  loadingHidden: boolean;
  items: Array<Price> = [];
  searchTerm: string;
  practicetype: string;
  pricetype: string;

  constructor(
    private modalController: ModalController,
    private http: HttpClient,
    private alertController: AlertController,
    private params: NavParams
  ) {
    this.searchTerm = '';
    this.loadingHidden = true;
    if (this.params.get('pricetype')) {
      this.pricetype = this.params.get('pricetype');
    }
    if (this.params.get('practicetype')) {
      this.practicetype = this.params.get('practicetype');
    }
  }

  ngOnInit() {}

  filterChanged() {
    if (this.searchTerm !== '' && this.searchTerm.length > 2) {
      this.list();
    }
  }

  list() {
    this.loadingHidden = false;

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));

    let url =
      AppGlobals.URL +
      'price?practicetype=' +
      this.practicetype +
      '&pricetype=' +
      this.pricetype;

    if (this.searchTerm !== '') {
      url += '&keyword=' + this.searchTerm;
    }

    this.http.get<any>(url, { headers }).subscribe(
      async (data) => {
        this.loadingHidden = true;

        this.items = data;
      },
      async (err) => {
        const alert = await this.alertController.create({
          header: 'Error',
          message: JSON.stringify(err),
          buttons: [
            {
              text: 'OK',
              handler: () => {},
            },
          ],
        });

        await alert.present();
      }
    );
  }

  async selectItem(item) {
    await this.modalController.dismiss(item);
  }

  async back() {
    await this.modalController.dismiss(null);
  }
}
